import CTACard from '../CTACard/CTACard';
import { SpaceWow } from '../SpaceWow';
import QuotePicture from '@/public/img/heros/InfoRow-Infinity-HVAC-Technician.webp';

export interface CTACard_RepairProps {
	testId?: string;
	image?: boolean;
}

const CTACard_Repair = (props: CTACard_RepairProps) => {
	const { testId = 'CTACard_Repair', image = false } = props;

	return (
		<CTACard testId={testId} type="link" color="amber" href="/repair/">
			{image && <CTACard.Image src={QuotePicture} alt="Reviewing the quote" />}
			<div className="px-6 py-8 lg:px-8 lg:pb-10 lg:pt-8">
				<CTACard.Badge color="amber">HVAC Repairs</CTACard.Badge>
				<SpaceWow spacer_01 lg__spacer_02 />
				<CTACard.Title>Repair your HVAC</CTACard.Title>
				<SpaceWow spacer_03 lg__spacer_04 />
				<CTACard.Content>
					Get your HVAC system repaired by a qualified technician from our
					trusted network of over 10,000 nationwide.
				</CTACard.Content>
				<SpaceWow spacer_04 lg__spacer_06 />
				<CTACard.Action color="amber">Learn more</CTACard.Action>
			</div>
		</CTACard>
	);
};

export default CTACard_Repair;
