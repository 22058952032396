import React, { ReactNode } from 'react';
import { Colors } from './types';
import { Badge } from '../Badge';

interface CTABadgeProps {
	testId?: string;
	className?: string;
	children: ReactNode;
	color: Colors;
	compact?: boolean;
}

const CTABadge = (props: CTABadgeProps) => {
	const {
		testId = 'CTABadge',
		className,
		children,
		color,
		compact = false,
	} = props;
	return (
		<Badge
			testId={testId}
			className={className}
			color={color}
			size={compact ? 'medium' : 'large'}
		>
			{children}
		</Badge>
	);
};

export default CTABadge;
