import CTACard from '../CTACard/CTACard';
import { SpaceWow } from '../SpaceWow';
// Image
import QuotePicture from '@/public/img/heros/InfoRow-Infinity-HVAC-Technician.webp';

export interface CTACard_ReplaceProps {
	testId?: string;
	image?: boolean;
}

const CTACard_Replace = (props: CTACard_ReplaceProps) => {
	const { testId = 'CTACard_Replace', image = false } = props;

	return (
		<CTACard testId={testId} type="link" color="pink" href="/replace/">
			{image && <CTACard.Image src={QuotePicture} alt="Reviewing the quote" />}
			<div className="px-6 py-8 lg:px-8 lg:pb-10 lg:pt-8">
				<CTACard.Badge color="pink">HVAC Replacement</CTACard.Badge>
				<SpaceWow spacer_01 lg__spacer_02 />
				<CTACard.Title>Replace your HVAC</CTACard.Title>
				<SpaceWow spacer_03 lg__spacer_04 />
				<CTACard.Content>
					Get a customized HVAC solution for your home with honest pricing, all
					backed by the best brands in the industry.
				</CTACard.Content>
				<SpaceWow spacer_04 lg__spacer_06 />
				<CTACard.Action color="pink">See how it works</CTACard.Action>
			</div>
		</CTACard>
	);
};

export default CTACard_Replace;
