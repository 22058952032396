import CTAAction from './CTAAction';
import CTABadge from './CTABadge';
import { CTACardButton, CTACardButtonProps } from './CTACardButton';
import { CTACardLink, CTACardLinkProps } from './CTACardLink';
import CTAContent from './CTAContent';
import CTAImage from './CTAImage';
import CTATitle from './CTATitle';

type CTAButtonProps = { type: 'button' } & CTACardButtonProps;
type CTALinkProps = { type: 'link' } & CTACardLinkProps;

export type CTACardProps = CTAButtonProps | CTALinkProps;

const CTACard = (props: CTACardProps) => {
	switch (props.type) {
		case 'button':
			return <CTACardButton {...props} />;
		case 'link':
			return <CTACardLink {...props} />;
		default:
			console.warn('CTACard: Invalid type provided');
			return null;
	}
};

CTACard.Action = CTAAction;
CTACard.Badge = CTABadge;
CTACard.Content = CTAContent;
CTACard.Image = CTAImage;
CTACard.Title = CTATitle;

export default CTACard;
