import cn from 'classnames';

export const SpacerOptions = {
	spacer_00: '',
	spacer_01: '',
	spacer_02: '',
	spacer_03: '',
	spacer_04: '',
	spacer_05: '',
	spacer_06: '',
	spacer_07: '',
	spacer_08: '',
	spacer_09: '',
	spacer_10: '',
	spacer_11: '',
	spacer_12: '',
	spacer_14: '',
	spacer_16: '',
	spacer_20: '',
	spacer_24: '',
	spacer_28: '',
	spacer_30: '',
	spacer_32: '',
	spacer_36: '',
	spacer_40: '',
} as const;

export const BreakpointOptions = {
	sm: '',
	md: '',
	lg: '',
	xl: '',
	xxl: '',
} as const;

export type SpacerKeys = keyof typeof SpacerOptions;
export type BreakpointKeys = keyof typeof BreakpointOptions;
export type BreakpointSpacerKeys = `${BreakpointKeys}__${SpacerKeys}`;

export type SpaceWowProps = {
	testId?: string;
} & {
	[K in BreakpointSpacerKeys]?: boolean;
} & { [K in SpacerKeys]?: boolean };

const SpaceWow = (props: SpaceWowProps) => {
	const { testId = 'SpaceWow' } = props;

	return (
		<div
			data-testid={testId}
			className={cn({
				'h-0': props.spacer_00,
				'h-1': props.spacer_01,
				'h-2': props.spacer_02,
				'h-3': props.spacer_03,
				'h-4': props.spacer_04,
				'h-5': props.spacer_05,
				'h-6': props.spacer_06,
				'h-7': props.spacer_07,
				'h-8': props.spacer_08,
				'h-9': props.spacer_09,
				'h-10': props.spacer_10,
				'h-11': props.spacer_11,
				'h-12': props.spacer_12,
				'h-14': props.spacer_14,
				'h-16': props.spacer_16,
				'h-20': props.spacer_20,
				'h-24': props.spacer_24,
				'h-28': props.spacer_28,
				'h-30': props.spacer_30,
				'h-32': props.spacer_32,
				'h-36': props.spacer_36,
				'h-40': props.spacer_40,
				'sm:h-0': props.sm__spacer_00,
				'sm:h-1': props.sm__spacer_01,
				'sm:h-2': props.sm__spacer_02,
				'sm:h-3': props.sm__spacer_03,
				'sm:h-4': props.sm__spacer_04,
				'sm:h-5': props.sm__spacer_05,
				'sm:h-6': props.sm__spacer_06,
				'sm:h-7': props.sm__spacer_07,
				'sm:h-8': props.sm__spacer_08,
				'sm:h-9': props.sm__spacer_09,
				'sm:h-10': props.sm__spacer_10,
				'sm:h-11': props.sm__spacer_11,
				'sm:h-12': props.sm__spacer_12,
				'sm:h-14': props.sm__spacer_14,
				'sm:h-16': props.sm__spacer_16,
				'sm:h-20': props.sm__spacer_20,
				'sm:h-24': props.sm__spacer_24,
				'sm:h-28': props.sm__spacer_28,
				'sm:h-30': props.sm__spacer_30,
				'sm:h-32': props.sm__spacer_32,
				'sm:h-36': props.sm__spacer_36,
				'sm:h-40': props.sm__spacer_40,
				'md:h-0': props.md__spacer_00,
				'md:h-1': props.md__spacer_01,
				'md:h-2': props.md__spacer_02,
				'md:h-3': props.md__spacer_03,
				'md:h-4': props.md__spacer_04,
				'md:h-5': props.md__spacer_05,
				'md:h-6': props.md__spacer_06,
				'md:h-7': props.md__spacer_07,
				'md:h-8': props.md__spacer_08,
				'md:h-9': props.md__spacer_09,
				'md:h-10': props.md__spacer_10,
				'md:h-11': props.md__spacer_11,
				'md:h-12': props.md__spacer_12,
				'md:h-14': props.md__spacer_14,
				'md:h-16': props.md__spacer_16,
				'md:h-20': props.md__spacer_20,
				'md:h-24': props.md__spacer_24,
				'md:h-28': props.md__spacer_28,
				'md:h-30': props.md__spacer_30,
				'md:h-32': props.md__spacer_32,
				'md:h-36': props.md__spacer_36,
				'md:h-40': props.md__spacer_40,
				'lg:h-0': props.lg__spacer_00,
				'lg:h-1': props.lg__spacer_01,
				'lg:h-2': props.lg__spacer_02,
				'lg:h-3': props.lg__spacer_03,
				'lg:h-4': props.lg__spacer_04,
				'lg:h-5': props.lg__spacer_05,
				'lg:h-6': props.lg__spacer_06,
				'lg:h-7': props.lg__spacer_07,
				'lg:h-8': props.lg__spacer_08,
				'lg:h-9': props.lg__spacer_09,
				'lg:h-10': props.lg__spacer_10,
				'lg:h-11': props.lg__spacer_11,
				'lg:h-12': props.lg__spacer_12,
				'lg:h-14': props.lg__spacer_14,
				'lg:h-16': props.lg__spacer_16,
				'lg:h-20': props.lg__spacer_20,
				'lg:h-24': props.lg__spacer_24,
				'lg:h-28': props.lg__spacer_28,
				'lg:h-30': props.lg__spacer_30,
				'lg:h-32': props.lg__spacer_32,
				'lg:h-36': props.lg__spacer_36,
				'lg:h-40': props.lg__spacer_40,
				'xl:h-0': props.xl__spacer_00,
				'xl:h-1': props.xl__spacer_01,
				'xl:h-2': props.xl__spacer_02,
				'xl:h-3': props.xl__spacer_03,
				'xl:h-4': props.xl__spacer_04,
				'xl:h-5': props.xl__spacer_05,
				'xl:h-6': props.xl__spacer_06,
				'xl:h-7': props.xl__spacer_07,
				'xl:h-8': props.xl__spacer_08,
				'xl:h-9': props.xl__spacer_09,
				'xl:h-10': props.xl__spacer_10,
				'xl:h-11': props.xl__spacer_11,
				'xl:h-12': props.xl__spacer_12,
				'xl:h-14': props.xl__spacer_14,
				'xl:h-16': props.xl__spacer_16,
				'xl:h-20': props.xl__spacer_20,
				'xl:h-24': props.xl__spacer_24,
				'xl:h-28': props.xl__spacer_28,
				'xl:h-30': props.xl__spacer_30,
				'xl:h-32': props.xl__spacer_32,
				'xl:h-36': props.xl__spacer_36,
				'xl:h-40': props.xl__spacer_40,
				'2xl:h-0': props.xxl__spacer_00,
				'2xl:h-1': props.xxl__spacer_01,
				'2xl:h-2': props.xxl__spacer_02,
				'2xl:h-3': props.xxl__spacer_03,
				'2xl:h-4': props.xxl__spacer_04,
				'2xl:h-5': props.xxl__spacer_05,
				'2xl:h-6': props.xxl__spacer_06,
				'2xl:h-7': props.xxl__spacer_07,
				'2xl:h-8': props.xxl__spacer_08,
				'2xl:h-9': props.xxl__spacer_09,
				'2xl:h-10': props.xxl__spacer_10,
				'2xl:h-11': props.xxl__spacer_11,
				'2xl:h-12': props.xxl__spacer_12,
				'2xl:h-14': props.xxl__spacer_14,
				'2xl:h-16': props.xxl__spacer_16,
				'2xl:h-20': props.xxl__spacer_20,
				'2xl:h-24': props.xxl__spacer_24,
				'2xl:h-28': props.xxl__spacer_28,
				'2xl:h-30': props.xxl__spacer_30,
				'2xl:h-32': props.xxl__spacer_32,
				'2xl:h-36': props.xxl__spacer_36,
				'2xl:h-40': props.xxl__spacer_40,
			})}
			role="presentation"
		/>
	);
};

export default SpaceWow;
