import cn from 'classnames';
import Image, { StaticImageData } from 'next/image';
import React from 'react';

interface CTAImageProps {
	testId?: string;
	src: StaticImageData;
	alt: string;
	className?: string;
}

const CTAImage = (props: CTAImageProps) => {
	const { testId = 'CTAImage', src, alt, className } = props;
	return (
		<Image
			data-testid={testId}
			src={src}
			alt={alt}
			className={cn(
				className === undefined && 'rounded-t-3xl lg:rounded-t-5xl',
				className,
			)}
		/>
	);
};

export default CTAImage;
