import cn from 'classnames';
import React, { ReactNode } from 'react';

interface CTATitleProps {
	testId?: string;
	children: ReactNode;
	className?: string;
	compact?: boolean;
}

const CTATitle = (props: CTATitleProps) => {
	const { testId = 'CTATitle', children, className, compact = false } = props;
	return (
		<p
			data-testid={testId}
			className={cn(
				'text-balance font-semibold',
				{
					'text-2xl tracking-tighter lg:text-4xl': !compact,
					'text-lg lg:text-xl lg:tracking-tighter': compact,
				},
				className,
			)}
		>
			{children}
		</p>
	);
};

export default CTATitle;
