import cn from 'classnames';
import React, { ReactNode } from 'react';

interface CTAContentProps {
	testId?: string;
	children: ReactNode;
	className?: string;
	compact?: boolean;
	isStrapiInlineComponent?: boolean;
}

const CTAContent = (props: CTAContentProps) => {
	const {
		testId = 'CTAContent',
		children,
		className,
		compact = false,
		isStrapiInlineComponent = false,
	} = props;
	return (
		<p
			data-testid={testId}
			className={cn(
				'text-balance font-normal tracking-tighter text-black',
				{ 'text-balance': !isStrapiInlineComponent },
				{
					'text-xl lg:text-2xl': !compact,
					'text-base': compact,
				},
				className,
			)}
		>
			{children}
		</p>
	);
};

export default CTAContent;
