// Modules
import { ButtonHTMLAttributes as HTMLButton, ReactNode } from 'react';
import cn from 'classnames';
// Types
import { Colors } from '../types';

export interface CTACardButtonProps extends HTMLButton<HTMLButtonElement> {
	testId?: string;
	color: Colors;
	children: ReactNode;
	compact?: boolean;
	isStrapiInlineComponent?: boolean;
}

const CTACardButton = (props: CTACardButtonProps) => {
	const {
		testId = 'CTACardButton',
		children,
		color,
		compact = false,
		className,
		isStrapiInlineComponent = false,
		...remainingProps
	} = props;

	return (
		<button
			data-testid={testId}
			className={cn(
				'group flex w-full text-left shadow-xl transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-2xl',
				{
					'flex-col': !isStrapiInlineComponent,
					'h-44 flex-row items-center': isStrapiInlineComponent,
				},
				{
					'rounded-3xl': !compact,
					'rounded-2xl': compact,
				},
				{
					'shadow-pink-500/10 hover:shadow-pink-600/10':
						color === 'hvac' || color === 'pink',
					'shadow-sky-600/10 hover:shadow-sky-700/10': color === 'sky',
					'shadow-red-600/10 hover:shadow-red-700/10': color === 'red',
					'shadow-amber-600/10 hover:shadow-amber-700/10': color === 'amber',
					'shadow-lime-600/10 hover:shadow-lime-700/10': color === 'lime',
				},
				className,
			)}
			{...remainingProps}
		>
			{children}
		</button>
	);
};

export default CTACardButton;
