import React, { ReactNode } from 'react';
import { IconArrowRight } from '../icons';
import cn from 'classnames';
import { Colors } from './types';

interface CTAActionProps {
	testId?: string;
	children: ReactNode;
	color: Colors;
	className?: string;
	compact?: boolean;
}

const CTAAction = (props: CTAActionProps) => {
	const {
		testId = 'CTAAction',
		children,
		className,
		color,
		compact = false,
	} = props;
	return (
		<div
			data-testid={testId}
			className={cn(
				'group flex items-center text-xl font-normal tracking-tighter lg:text-2xl',
				{
					'lg:text-base': compact,
				},
				{
					'text-pink-600 active:text-pink-700 [@media(hover:hover)]:group-hover:text-pink-700':
						color === 'hvac' || color === 'pink',
					'text-lime-700 active:text-lime-800 [@media(hover:hover)]:group-hover:text-lime-800':
						color === 'lime',
					'text-sky-700 active:text-sky-800 [@media(hover:hover)]:group-hover:text-sky-800':
						color === 'sky',
					'text-red-700 active:text-red-800 [@media(hover:hover)]:group-hover:text-red-800':
						color === 'red',
					'text-amber-700 active:text-amber-800 [@media(hover:hover)]:group-hover:text-amber-800':
						color === 'amber',
				},
				className,
			)}
		>
			{children}
			<IconArrowRight
				className={cn(
					'ml-1',
					{
						'h-6 w-auto lg:h-7': !compact,
						'h-5 w-auto': compact,
					},
					{
						'fill-pink-600 group-hover:fill-pink-700':
							color === 'hvac' || color === 'pink',
						'fill-lime-700 group-hover:fill-lime-800': color === 'lime',
						'fill-sky-700 group-hover:fill-sky-800': color === 'sky',
						'fill-red-700 group-hover:fill-red-800': color === 'red',
						'fill-amber-700 group-hover:fill-amber-800': color === 'amber',
					},
				)}
			/>
		</div>
	);
};

export default CTAAction;
